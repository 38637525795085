import React from 'react';
import { Panel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import * as liveEventGroupsVmsApi from '../../../actions/apiWrappers/vmsApi/common/liveEventGroups';

class LiveEventGroupPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLiveEventGroup: null,
      options: [],
      error: false,
    };
    this.onLiveEventGroupChange = this.onLiveEventGroupChange.bind(this);
    this.loadLiveEventGroups = this.loadLiveEventGroups.bind(this);
    this.loadLiveEventGroupById = this.loadLiveEventGroupById.bind(this);
    this.abortController = null;
  }

  componentDidMount() {
    const { liveEvent } = this.props;
    if (liveEvent && liveEvent.liveEventGroupId) {
      this.loadLiveEventGroupById(liveEvent.liveEventGroupId);
    }
  }

  async loadLiveEventGroupById(liveEventGroupId) {
    try {
      const result = await liveEventGroupsVmsApi.getLiveEventGroupById(liveEventGroupId);
      if (result.liveEventGroup) {
        this.setState({
          selectedLiveEventGroup: {
            id: result.liveEventGroup.id,
            name: result.liveEventGroup.name,
          },
        });
      }
    } catch (error) {
      console.error(`Error fetching live event group with ID:${liveEventGroupId}:`, error);
      this.setState({ selectedLiveEventGroup: null, error: true });
    }
  }

  onLiveEventGroupChange(selectedLiveEventGroupOption) {
    const liveEventGroupId = selectedLiveEventGroupOption
      ? parseInt(selectedLiveEventGroupOption.id, 10)
      : null;
    this.setState({ selectedLiveEventGroup: selectedLiveEventGroupOption });
    this.props.onLiveEventGroupChange(liveEventGroupId);
  }

  async loadLiveEventGroups(inputValue) {
    if (!inputValue) {
      return { options: [] };
    }

    const params = { name: `like:${inputValue}`, limit: 20 };

    const abortMsg = 'Cancelling existing request, to send new request with new parameters.';

    // Check if there's an ongoing request, cancel it if exists
    if (this.abortController) {
      this.abortController.abort(new Error(abortMsg));
    }

    // Create a new AbortController for the current request
    this.abortController = new window.AbortController();
    const signal = this.abortController.signal;

    try {
      const result = await liveEventGroupsVmsApi.getLiveEventGroups(params, signal);
      const options = result.liveEventGroups.map((group) => ({
        id: group.id,
        name: group.name,
      }));
      this.abortController = null;
      this.setState({ options });
      return { options };
    } catch (error) {
      // If the error is due to aborting the request, return the existing options as to not clear the dropdown
      if (error.message === abortMsg || error.name === 'AbortError') {
        return this.state.options;
      }
      console.error('Error fetching live event groups:', error);
      this.abortController = null;
      this.setState({ options: [], error: true });
      return { options: [] };
    }
  }

  render() {
    const { validationErrors } = this.props;
    const { error, selectedLiveEventGroup } = this.state;
    const errorMessage = validationErrors || (error && 'Error fetching live event group');
    return (
      <Panel bsStyle="primary" header="Event Group">
        <label ref="liveEventGroupSelectLabel" className="control-label required">
          Live Event Group
        </label>
        <Select.Async
          autoload={false}
          cache={false}
          multi={false}
          name="liveEventGroup-field-name"
          placeholder={errorMessage || 'Type to Search a Live Event Group'}
          noResultsText="No Live Event Groups found"
          loadOptions={this.loadLiveEventGroups}
          deleteRemoves
          backspaceRemoves={false}
          value={selectedLiveEventGroup}
          onChange={this.onLiveEventGroupChange}
          isClearable
          className={`Select ${errorMessage ? 'error' : ''}`}
          data-pw="event-group"
          valueKey="id"
          labelKey="name"
          ref="selectEventGroup"
          id="select-eventGroup"
        />
      </Panel>
    );
  }
}

LiveEventGroupPanel.propTypes = {
  liveEvent: PropTypes.object,
  onLiveEventGroupChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.string.isRequired,
};

export default LiveEventGroupPanel;

import { getDevices, patchDevice } from './apiWrappers/vmsApi/admin';

export function listDevices() {
  return getDevices();
}

export function updateDeviceSsai(deviceId, target, toEnable, reason) {
  const path = `${toEnable ? 'enable' : 'disable'}-${target}-ssai`;
  return patchDevice(deviceId, path, reason);
}

export function updateDeviceDrm(deviceId, toEnable, reason) {
  const path = `${toEnable ? 'enable' : 'disable'}-drm`;
  return patchDevice(deviceId, path, reason);
}

export function updateDeviceFullHd(deviceId, toEnable, reason) {
  const path = `${toEnable ? 'enable' : 'disable'}-full-hd`;
  return patchDevice(deviceId, path, reason);
}

export function updateDeviceStartOver(deviceId, toEnable, reason) {
  const path = `${toEnable ? 'enable' : 'disable'}-start-over`;
  return patchDevice(deviceId, path, reason);
}

export function updateDeviceCdn(deviceId, cdnName, toEnable, reason) {
  const path = `${toEnable ? 'enable' : 'disable'}-cdn-${cdnName.toLowerCase()}`;
  return patchDevice(deviceId, path, reason);
}

export function updateDeviceTokenisation(deviceId, toEnable, reason) {
  const path = `${toEnable ? 'enable' : 'disable'}-tokenisation`;
  return patchDevice(deviceId, path, reason);
}

export function updateDeviceManifestFiltering(deviceId, toEnable, reason) {
  const path = `${toEnable ? 'enable' : 'disable'}-manifest-filtering`;
  return patchDevice(deviceId, path, reason);
}

export function updateDeviceAmsEnabledVoD(deviceId, toEnable, reason) {
  const path = `${toEnable ? 'enable' : 'disable'}-vod-ams-enabled`;
  return patchDevice(deviceId, path, reason);
}

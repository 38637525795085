import _ from 'lodash';
import environment from '../../../config/environment';

export function immutableRemoveById(list, id) {
  const clonedList = list.slice(0);
  const index = _.map(clonedList, 'id').indexOf(id);
  if (index > -1) {
    clonedList.splice(index, 1);
  }
  return clonedList;
}

export function immutableUpdateArrayItem(list, payload) {
  const index = _.findIndex(list, { id: payload.id });
  if (index === -1) {
    return list;
  }
  return list
    .slice(0, index)
    .concat([_.merge(list[index], payload)])
    .concat(list.slice(index + 1));
}

export function isDefaultOptionSelected(selectComponent) {
  const selectedOptionIndex = selectComponent.getInputDOMNode().selectedIndex;
  return selectedOptionIndex === 0;
}

export function truncateString(string, cutAt) {
  if (string.length > cutAt) {
    return `${string.substring(0, cutAt)}...`;
  }
  return string;
}

export const getRandomIntegerBetweenBounds = (min, max) =>
  Math.floor(Math.random() * (max - (min + 1))) + (min + 1);

export const moveItemInList = (list, currentIdx, targetIdx) => {
  const item = list[currentIdx];
  const updatedList = [...list];
  updatedList.splice(currentIdx, 1);
  updatedList.splice(targetIdx, 0, item);
  return updatedList;
};

export const removeItemInList = (list, itemIdx) => {
  const updatedList = [...list];
  updatedList.splice(itemIdx, 1);
  return updatedList;
};

export const isDefaultImage = (image) => {
  return image.alt === 'Default Image';
};

export const showCdnToggles = () => {
  // eslint-disable-next-line
  return true;
};

export const showAdTagTemplateOverrideToggles = () => {
  return environment.featureFlags.liveChannelsAdTagTemplateOverride;
};

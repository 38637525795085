import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ItemList from '../../components/ItemList/ItemList';
import ItemListHeader from '../../components/ItemList/ItemListHeader';
import NavDropdownMenu from '../../components/NavDropdownMenu';
import ModalItem from '../../components/ModalItem';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import {
  getChannels,
  switchToPrimary,
  switchToBackup,
  enableSsai,
  disableSsai,
  enableDrm,
  disableDrm,
  enableFullHd,
  disableFullHd,
  enableStartOver,
  disableStartOver,
  toggleCdn,
  toggleCdnTokenisation,
  enableFullHdMarket,
  disableFullHdMarket,
  enableStartOverMarket,
  disableStartOverMarket,
  toggleMarketExpanded,
  switchFhdToBackupMarket,
  switchFhdToPrimaryMarket,
  enableManifestFiltering,
  disableManifestFiltering,
  toggleTagTemplateForChannel,
} from '../../actions/channel';
import { IMAGE_NOT_FOUND_URL } from '../../constants/common';
import environment from 'config/environment';
import { CdnCollection } from '../../lib/cdn';
import { showCdnToggles, showAdTagTemplateOverrideToggles } from '../../lib/util';
import { getMediaLiveChannelName } from '../../lib/channelBackupStreamMappings.js';
import { getMediaLiveChannels } from '../../actions/apiWrappers/vmsApi/common/liveEvents.js';
import {
  CHANNEL_CARD_LOGO_TRANSPARENT,
  CHANNEL_CARD_TRANSPARENT,
  CHANNEL_CARD_LIVE,
  MARKET_CHANNEL_SWITCHER_LOGO,
} from './channelImageTypes.js';
import ChannelStylingModal from './ChannelStylingModal.jsx';

const EMPTY_CELL = '';

function isLiveChannel(channel) {
  return channel.slug !== '9hd' && channel.slug !== '9now' && channel.slug !== 'ptv';
}

function isPrimary(marketAsChannel) {
  return !marketAsChannel.fhdActiveStream || marketAsChannel.fhdActiveStream === 'PRIMARY';
}

function isMarket(channel) {
  return !!channel.isMarket;
}

function isChannelActiveInMediaLive(mediaLiveStream) {
  return mediaLiveStream.channels.length && mediaLiveStream.channels[0].state === 'RUNNING';
}

function getInvitationText(isLoading, isActive, channelMarketData) {
  if (isLoading) {
    return 'Checking Stream Status In Media Live...';
  }
  if (isActive) {
    return `Are you sure you wish to switch to ${channelMarketData.switchStreamActionName} for ${channelMarketData.marketAsChannel.name}?`;
  }
  return `Stream ${channelMarketData.channelName} is inactive in Media Live.`;
}
export class ChannelList extends React.Component {
  imageTypesOptions = [
    MARKET_CHANNEL_SWITCHER_LOGO,
    CHANNEL_CARD_LOGO_TRANSPARENT,
    CHANNEL_CARD_TRANSPARENT,
    CHANNEL_CARD_LIVE,
  ];

  constructor(props) {
    super(props);
    this.state = {
      isMediaLiveStreamActive: false,
      isLoading: false,
    };
  }

  async listAndVerifyIfActiveInMediaLive(channelName) {
    try {
      this.setState({ isLoading: true, isMediaLiveStreamActive: false });
      const medialLiveStreams = await getMediaLiveChannels({
        channelName,
        includeDetails: true,
      });
      const isActive = isChannelActiveInMediaLive(medialLiveStreams);
      this.setState({ isMediaLiveStreamActive: isActive, isLoading: false });
    } catch (error) {
      this.setState({ isMediaLiveStreamActive: false, isLoading: false });
    }
  }

  renderPrimaryBackupToggle(channel) {
    if (!environment.featureFlags.allowChannelActiveStreamToggle) {
      return null;
    }

    const streamToSwitchTo = channel.activeStream === 'PRIMARY' ? 'Backup' : 'Primary';
    const streamToggleAction =
      channel.activeStream === 'PRIMARY'
        ? (reason) => this.props.switchToBackup(channel.id, reason)
        : (reason) => this.props.switchToPrimary(channel.id, reason);
    return (
      <ModalItem
        title={`Switch Legacy to ${streamToSwitchTo}`}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you want to switch ${channel.name} to ${streamToSwitchTo}?`}
            ok={streamToggleAction}
            buttonClass="warning"
            buttonLabel={`Switch to ${streamToSwitchTo}`}
            reasonRequired
          />
        }
      />
    );
  }

  renderSsaiToggle(channel) {
    const ssaiActionName = channel.ssaiEnabled ? 'Disable' : 'Enable';
    const ssaiAction = channel.ssaiEnabled
      ? (reason) => this.props.disableSsai(channel.id, reason)
      : (reason) => this.props.enableSsai(channel.id, reason);
    return (
      <ModalItem
        title={`${ssaiActionName} Ad Insertion`}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you wish to ${ssaiActionName} SSAI for ${channel.name}?`}
            ok={ssaiAction}
            buttonClass="warning"
            buttonLabel={`${ssaiActionName} SSAI`}
            reasonRequired
          />
        }
      />
    );
  }

  renderDrmToggle(channel) {
    const drmActionName = channel.drmEnabled ? 'Disable' : 'Enable';
    const drmAction = channel.drmEnabled
      ? (reason) => this.props.disableDrm(channel.id, reason)
      : (reason) => this.props.enableDrm(channel.id, reason);

    return (
      <ModalItem
        title={`${drmActionName} DRM`}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you wish to ${drmActionName} DRM for ${channel.name}?`}
            ok={drmAction}
            buttonClass={'warning'}
            buttonLabel={`${drmActionName} DRM`}
            reasonRequired
          />
        }
      />
    );
  }

  renderFullHdToggle(channel) {
    const fullHdActionName = channel.fullHdEnabled ? 'Disable' : 'Enable';
    const fullHdAction = channel.fullHdEnabled
      ? (reason) => this.props.disableFullHd(channel.id, reason)
      : (reason) => this.props.enableFullHd(channel.id, reason);

    return (
      <ModalItem
        title={`${fullHdActionName} Full HD`}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you wish to ${fullHdActionName} Full HD for ${channel.name}?`}
            ok={fullHdAction}
            buttonClass={'warning'}
            buttonLabel={`${fullHdActionName} Full HD`}
            reasonRequired
          />
        }
      />
    );
  }

  renderStartOverToggle(channel) {
    const startOverActionName = channel.startOverEnabled ? 'Disable' : 'Enable';
    const startOverAction = channel.startOverEnabled
      ? (reason) => this.props.disableStartOver(channel.id, reason)
      : (reason) => this.props.enableStartOver(channel.id, reason);

    return (
      <ModalItem
        title={`${startOverActionName} Start Over`}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you wish to ${startOverActionName} Start Over for ${channel.name}?`}
            ok={startOverAction}
            buttonClass={'warning'}
            buttonLabel={`${startOverActionName} Start Over`}
            reasonRequired
          />
        }
      />
    );
  }

  renderTokenisationToggles(channel) {
    const nextStatuses = [true, false];
    const cdnCollection = new CdnCollection(channel.cdn);
    return cdnCollection.getCdnList().flatMap((cdn) => {
      return nextStatuses.map((nextStatus) => {
        if (channel.tokenisation[cdn.getCdnName()].enabled === nextStatus) {
          return <span></span>;
        }

        const actionName = `${nextStatus ? 'Enable' : 'Disable'} ${cdn.getCdnName()} Tokenisation`;
        const action = (reason) =>
          this.props.toggleCdnTokenisation(channel.id, cdn.getCdnName(), nextStatus, reason);

        return (
          <ModalItem
            title={actionName}
            key={actionName}
            form={
              <ConfirmationDialog
                invitation={`Are you sure you wish to ${actionName} for ${channel.name}`}
                ok={action}
                buttonClass={'warning'}
                buttonLabel={actionName}
                reasonRequired
              />
            }
          />
        );
      });
    });
  }

  renderAdTagTemplateOverrideToggle(channel) {
    const allowedTagTemplateOverrides = ['AMS', 'NON_AMS'];

    const channelAdTemplateOverrideOptions = allowedTagTemplateOverrides.filter(
      (allowedOverride) => allowedOverride !== channel.adTagTemplateOverride
    );
    const adTagTemplateOverrideToggles = channelAdTemplateOverrideOptions.map((option) => (
      <ModalItem
        title={`Enable ${option} Override`}
        key={`Enable ${option} Override`}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you want to override AD Tag Template for ${channel.name} to ${option}?`}
            ok={(reason) => this.props.toggleTagTemplateForChannel(channel.id, true, option, reason)}
            buttonClass={'warning'}
            buttonLabel={'Enable Override'}
            reasonRequired
          />
        }
      />
    ));

    if (!!channel.adTagTemplateOverride) {
      adTagTemplateOverrideToggles.push(
        <ModalItem
          title={'Disable Ad Tag Template Override'}
          key={'Disable Ad Tag Template Override'}
          form={
            <ConfirmationDialog
              invitation={`Are you sure you want to Disable AD Tag Template override for ${channel.name}?`}
              ok={(reason) => this.props.toggleTagTemplateForChannel(channel.id, false, undefined, reason)}
              buttonClass={'warning'}
              buttonLabel={'Disable Override'}
              reasonRequired
            />
          }
        />
      );
    }

    return adTagTemplateOverrideToggles;
  }

  renderTokenisationSettings(channel) {
    const tokenisationSettings = channel.tokenisation;

    if (tokenisationSettings) {
      return Object.entries(tokenisationSettings).map((setting) => {
        const cdn = setting[0];
        const tokenisationSetting = setting[1].enabled;

        return (
          <span key={cdn}>
            {cdn} [{tokenisationSetting ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}]
            <br />
          </span>
        );
      });
    }

    return EMPTY_CELL;
  }

  renderFullHdMarketToggle(marketAsChannel) {
    const label = marketAsChannel.fullHdEnabled ? 'Yes' : 'No';
    const fullHdActionName = marketAsChannel.fullHdEnabled ? 'Disable' : 'Enable';
    const fullHdAction = marketAsChannel.fullHdEnabled
      ? (reason) => this.props.disableFullHdMarket(marketAsChannel.id, reason)
      : (reason) => this.props.enableFullHdMarket(marketAsChannel.id, reason);

    return (
      <ModalItem
        className={label === 'No' ? 'strong-red' : ''}
        component={'span'}
        label={label}
        title={`${fullHdActionName} Full HD (${marketAsChannel.name})`}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you wish to ${fullHdActionName} Full HD for ${marketAsChannel.name}?`}
            ok={fullHdAction}
            buttonClass={'warning'}
            buttonLabel={`${fullHdActionName} Full HD (${marketAsChannel.name})`}
            reasonRequired
          />
        }
      />
    );
  }

  renderStartOverMarketToggle(marketAsChannel) {
    const label = marketAsChannel.startOverEnabled ? 'Yes' : 'No';
    const startOverActionName = marketAsChannel.startOverEnabled ? 'Disable' : 'Enable';
    const startOverAction = marketAsChannel.startOverEnabled
      ? (reason) => this.props.disableStartOverMarket(marketAsChannel.id, reason)
      : (reason) => this.props.enableStartOverMarket(marketAsChannel.id, reason);

    return (
      <ModalItem
        className={label === 'No' ? 'strong-red' : ''}
        component={'span'}
        label={label}
        title={`${startOverActionName} Start Over (${marketAsChannel.name})`}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you wish to ${startOverActionName} Start Over for ${marketAsChannel.name}?`}
            ok={startOverAction}
            buttonClass={'warning'}
            buttonLabel={`${startOverActionName} Start Over (${marketAsChannel.name})`}
            reasonRequired
          />
        }
      />
    );
  }

  renderSwitchStreamMarketToggle(marketAsChannel) {
    if (marketAsChannel.slug !== 'channel-9') {
      return '';
    }
    const label = isPrimary(marketAsChannel) ? 'Primary' : 'Backup';
    const switchStreamActionName = isPrimary(marketAsChannel) ? 'Backup' : 'Primary';
    const channelName = getMediaLiveChannelName(
      marketAsChannel.slug,
      marketAsChannel.name,
      switchStreamActionName
    );
    const switchStreamAction = isPrimary(marketAsChannel)
      ? (reason) => this.props.switchFhdToBackupMarket(marketAsChannel.id, reason)
      : (reason) => this.props.switchFhdToPrimaryMarket(marketAsChannel.id, reason);
    const invitationText = getInvitationText(this.state.isLoading, this.state.isMediaLiveStreamActive, {
      switchStreamActionName,
      marketAsChannel,
      channelName,
    });

    return (
      <ModalItem
        component={'span'}
        label={label}
        title={`Switch To ${switchStreamActionName} (${marketAsChannel.name})`}
        onClickHandlers={[async () => await this.listAndVerifyIfActiveInMediaLive(channelName)]}
        form={
          <ConfirmationDialog
            invitation={invitationText}
            ok={switchStreamAction}
            buttonClass={'warning'}
            buttonLabel={`Switch To ${switchStreamActionName} (${marketAsChannel.name})`}
            reasonRequired
            isConfirmDisabled={this.state.isLoading || !this.state.isMediaLiveStreamActive}
          />
        }
      />
    );
  }

  renderCdnToggles(channel) {
    const nextStatuses = [true, false];
    const cdnCollection = new CdnCollection(channel.cdn);
    return cdnCollection.getCdnList().flatMap((cdn) => {
      return nextStatuses.map((nextStatus) => {
        if (!(cdn.isActivated() ^ nextStatus)) {
          return <span></span>;
        }

        const actionName = `${nextStatus ? 'Enable' : 'Disable'} ${cdn.getCdnName()}`;
        const action = (reason) => this.props.toggleCdn(channel.id, cdn.getCdnName(), nextStatus, reason);

        return (
          <ModalItem
            title={actionName}
            form={
              <ConfirmationDialog
                invitation={`Are you sure you wish to ${actionName} for ${channel.name}`}
                ok={action}
                buttonClass={'warning'}
                buttonLabel={actionName}
                reasonRequired
              />
            }
          />
        );
      });
    });
  }

  renderChannelActions(channel) {
    return (
      <NavDropdownMenu
        component="div"
        buttonClass="btn btn-default"
        title={<i className="fa fa-ellipsis-v"></i>}
      >
        {this.renderPrimaryBackupToggle(channel)}
        {this.renderSsaiToggle(channel)}
        {this.renderDrmToggle(channel)}
        {this.renderFullHdToggle(channel)}
        {this.renderStartOverToggle(channel)}
        {showCdnToggles() && this.renderCdnToggles(channel)}
        {showCdnToggles() && this.renderTokenisationToggles(channel)}
        {this.renderManifestFilteringToggle(channel)}
        {showAdTagTemplateOverrideToggles() && this.renderAdTagTemplateOverrideToggle(channel)}
        <ChannelStylingModal channel={channel} />
      </NavDropdownMenu>
    );
  }

  channelNameWithTogglingMarketExpanded = (channel) => (
    <div
      className={'add-btn'}
      onClick={() => this.props.toggleMarketExpanded(channel)}
      data-pw="channel-trigger"
    >
      {channel.name}
    </div>
  );

  renderManifestFilteringToggle(channel) {
    const manifestFilteringActionName = channel.manifestFilteringEnabled ? 'Disable' : 'Enable';
    const manifestFilteringAction = channel.manifestFilteringEnabled
      ? (reason) => this.props.disableManifestFiltering(channel.id, reason)
      : (reason) => this.props.enableManifestFiltering(channel.id, reason);

    return (
      <ModalItem
        title={`${manifestFilteringActionName} Manifest Filtering`}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you wish to ${manifestFilteringActionName} Manifest Filtering for ${channel.name}?`}
            ok={manifestFilteringAction}
            buttonClass={'warning'}
            buttonLabel={`${manifestFilteringActionName} Manifest Filtering`}
            reasonRequired
          />
        }
      />
    );
  }

  getChannels = () => this.props.getChannels(this.props.marketExpanded);

  render() {
    const list = {
      pageData: this.props.channels.filter((channel) => isLiveChannel(channel)),
      totalCount: this.props.count || this.props.channels.length,
    };
    return (
      <div>
        <div data-pw="channels-list">
          <div className="mi9-breadcrumb clearfix">
            <ol className="mi9-breadcrumb__list breadcrumb">
              <li>{'Channels'}</li>
            </ol>
          </div>
          <ItemList id="list-channel" list={list} disableColumnChooser getItems={this.getChannels}>
            <ItemListHeader
              label="Image"
              dataField="image"
              dataFormat={(channel) => {
                if (isMarket(channel)) {
                  return EMPTY_CELL;
                }

                return (
                  <span className="row-image-span">
                    <img
                      ref="img"
                      className="row-image"
                      src={(channel.image && channel.image.url) || IMAGE_NOT_FOUND_URL}
                      alt={(channel.image && channel.image.alt) || ''}
                    />
                  </span>
                );
              }}
            />
            <ItemListHeader
              label={'Channel'}
              dataFormat={(channel) => {
                if (isMarket(channel)) {
                  return channel.name;
                }

                return this.channelNameWithTogglingMarketExpanded(channel);
              }}
            />
            <ItemListHeader
              label="Active Stream"
              dataFormat={(channel) => {
                if (isMarket(channel)) {
                  return this.renderSwitchStreamMarketToggle(channel);
                }

                if (!environment.featureFlags.allowChannelActiveStreamToggle) {
                  return 'Check Production Settings';
                }
                return channel.activeStream === 'PRIMARY' ? 'Primary' : 'Backup';
              }}
            />
            <ItemListHeader
              label="Ad Insertion Enabled"
              dataFormat={(channel) => {
                if (isMarket(channel)) {
                  return '';
                }

                return channel.ssaiEnabled ? 'Yes' : 'No';
              }}
            />
            <ItemListHeader
              label={'DRM Enabled'}
              dataFormat={(channel) => {
                if (isMarket(channel)) {
                  return EMPTY_CELL;
                }

                return channel.drmEnabled ? 'Yes' : 'No';
              }}
            />
            <ItemListHeader
              label={'Full HD Enabled'}
              dataFormat={(channel) => {
                if (isMarket(channel)) {
                  return this.renderFullHdMarketToggle(channel);
                }

                return channel.fullHdEnabled ? 'Yes' : 'No';
              }}
            />
            <ItemListHeader
              label={'Start Over Enabled'}
              dataFormat={(channel) => {
                if (isMarket(channel)) {
                  return this.renderStartOverMarketToggle(channel);
                }

                return channel.startOverEnabled ? 'Yes' : 'No';
              }}
            />
            {showCdnToggles() && (
              <ItemListHeader
                label={'CDN Enabled'}
                dataFormat={(channel) => {
                  if (isMarket(channel)) {
                    return EMPTY_CELL;
                  }

                  const cdnCollection = new CdnCollection(channel.cdn);
                  return Object.keys(cdnCollection.toJson(true)).join('/');
                }}
              />
            )}
            <ItemListHeader
              label={'Manifest Filtering Enabled'}
              dataFormat={(channel) => {
                if (isMarket(channel)) {
                  return EMPTY_CELL;
                }

                return channel.manifestFilteringEnabled ? 'Yes' : 'No';
              }}
            />
            {showCdnToggles() && (
              <ItemListHeader
                label={'Tokenisation Enabled'}
                dataFormat={(channel) => {
                  if (isMarket(channel)) {
                    return EMPTY_CELL;
                  }

                  return this.renderTokenisationSettings(channel);
                }}
              />
            )}
            {showAdTagTemplateOverrideToggles() && (
              <ItemListHeader
                label={'Ad Tag Template Override'}
                dataFormat={(channel) => {
                  if (!channel.adTagTemplateOverride) {
                    return EMPTY_CELL;
                  }

                  return channel.adTagTemplateOverride;
                }}
              />
            )}
            <ItemListHeader
              label="Action"
              dataFormat={(channel) => (isMarket(channel) ? '' : this.renderChannelActions(channel))}
            />
          </ItemList>
        </div>
      </div>
    );
  }
}

ChannelList.propTypes = {
  toggleMarketExpanded: PropTypes.func,
  getChannels: PropTypes.func,
  switchToBackup: PropTypes.func,
  switchToPrimary: PropTypes.func,
  enableSsai: PropTypes.func,
  disableSsai: PropTypes.func,
  enableDrm: PropTypes.func,
  disableDrm: PropTypes.func,
  enableFullHd: PropTypes.func,
  disableFullHd: PropTypes.func,
  enableFullHdMarket: PropTypes.func,
  disableFullHdMarket: PropTypes.func,
  enableStartOver: PropTypes.func,
  disableStartOver: PropTypes.func,
  enableStartOverMarket: PropTypes.func,
  disableStartOverMarket: PropTypes.func,
  switchFhdToBackupMarket: PropTypes.func,
  switchFhdToPrimaryMarket: PropTypes.func,
  toggleCdn: PropTypes.func,
  toggleCdnTokenisation: PropTypes.func,
  toggleTagTemplateForChannel: PropTypes.func,
  onDismiss: PropTypes.func,
  channels: PropTypes.array,
  marketExpanded: PropTypes.object,
  markets: PropTypes.array,
  count: PropTypes.number,
  offset: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    channels: state.channels.channels,
    marketExpanded: state.channels.marketExpanded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleMarketExpanded: (channel) => dispatch(toggleMarketExpanded(channel)),
    getChannels: (marketExpanded) => dispatch(getChannels(marketExpanded)),
    switchToPrimary: (channelId, reason) => dispatch(switchToPrimary(channelId, reason)),
    switchToBackup: (channelId, reason) => dispatch(switchToBackup(channelId, reason)),
    enableSsai: (channelId, reason) => dispatch(enableSsai(channelId, reason)),
    disableSsai: (channelId, reason) => dispatch(disableSsai(channelId, reason)),
    enableDrm: (channelId, reason) => dispatch(enableDrm(channelId, reason)),
    disableDrm: (channelId, reason) => dispatch(disableDrm(channelId, reason)),
    enableFullHd: (channelId, reason) => dispatch(enableFullHd(channelId, reason)),
    disableFullHd: (channelId, reason) => dispatch(disableFullHd(channelId, reason)),
    enableFullHdMarket: (marketChannelId, reason) => dispatch(enableFullHdMarket(marketChannelId, reason)),
    disableFullHdMarket: (marketChannelId, reason) => dispatch(disableFullHdMarket(marketChannelId, reason)),
    enableStartOver: (channelId, reason) => dispatch(enableStartOver(channelId, reason)),
    disableStartOver: (channelId, reason) => dispatch(disableStartOver(channelId, reason)),
    enableStartOverMarket: (marketChannelId, reason) =>
      dispatch(enableStartOverMarket(marketChannelId, reason)),
    disableStartOverMarket: (marketChannelId, reason) =>
      dispatch(disableStartOverMarket(marketChannelId, reason)),
    switchFhdToBackupMarket: (marketChannelId, reason) =>
      dispatch(switchFhdToBackupMarket(marketChannelId, reason)),
    switchFhdToPrimaryMarket: (marketChannelId, reason) =>
      dispatch(switchFhdToPrimaryMarket(marketChannelId, reason)),
    toggleCdn: (channelId, cdnName, toggleOn, reason) =>
      dispatch(toggleCdn(channelId, cdnName, toggleOn, reason)),
    toggleCdnTokenisation: (channelId, cdnName, toggleOn, reason) =>
      dispatch(toggleCdnTokenisation(channelId, cdnName, toggleOn, reason)),
    toggleTagTemplateForChannel: (channelId, toggleOn, tagTemplateOverride, reason) =>
      dispatch(toggleTagTemplateForChannel(channelId, toggleOn, tagTemplateOverride, reason)),
    enableManifestFiltering: (channelId, reason) => dispatch(enableManifestFiltering(channelId, reason)),
    disableManifestFiltering: (channelId, reason) => dispatch(disableManifestFiltering(channelId, reason)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelList);

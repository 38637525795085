import * as requestHandler from './requestHandler';

const PREFIX = 'api/admin';

export function getAzureRoles() {
  return requestHandler.send('GET', `${PREFIX}/roles/azure-roles`);
}

export function deleteRole(id) {
  return requestHandler.send('DELETE', `${PREFIX}/roles/${id}`);
}

export function getRoles() {
  return requestHandler.send('GET', `${PREFIX}/roles`);
}

export function getRoleById(roleId) {
  return requestHandler.send('GET', `${PREFIX}/roles/${roleId}`);
}

export function createRole(role) {
  return requestHandler.send('POST', `${PREFIX}/roles`, JSON.stringify(role));
}

export function updateRole(role) {
  return requestHandler.send('PATCH', `${PREFIX}/roles/${role.id}`, JSON.stringify(role));
}

export function switchChannelToPrimary(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/switch-to-primary`,
    JSON.stringify({ reason })
  );
}

export function switchChannelToBackup(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/switch-to-backup`,
    JSON.stringify({ reason })
  );
}

export function enableSsaiForChannel(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/enable-ssai`,
    JSON.stringify({ reason })
  );
}

export function disableSsaiForChannel(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/disable-ssai`,
    JSON.stringify({ reason })
  );
}

export function enableDrmForChannel(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/enable-drm`,
    JSON.stringify({ reason })
  );
}

export function disableDrmForChannel(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/disable-drm`,
    JSON.stringify({ reason })
  );
}

export function enableFullHdForChannel(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/enable-full-hd`,
    JSON.stringify({ reason })
  );
}

export function disableFullHdForChannel(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/disable-full-hd`,
    JSON.stringify({ reason })
  );
}

export function enableStartOverForChannel(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/enable-start-over`,
    JSON.stringify({ reason })
  );
}

export function disableStartOverForChannel(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/disable-start-over`,
    JSON.stringify({ reason })
  );
}

export function toggleTagTemplateForChannel(channelId, toggleOn, adTagTemplateOverride, reason) {
  const action = toggleOn ? 'enable' : 'disable';
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/${action}-ad-tag-template-override`,
    JSON.stringify({ reason, featureOption: adTagTemplateOverride })
  );
}

/**
 * @param channelId {number}
 * @param cdnName {string}
 * @param toggleOn {boolean}
 * @param reason {string}
 */
export function toggleCdnForChannel(channelId, cdnName, toggleOn, reason) {
  const cdnNameLowercase = cdnName.toLowerCase();
  const action = toggleOn ? 'enable' : 'disable';
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/${action}-cdn-${cdnNameLowercase}`,
    JSON.stringify({ reason })
  );
}

/**
 * @param channelId {number}
 * @param cdnName {string}
 * @param toggleOn {boolean}
 * @param reason {string}
 */
export function toggleCdnTokenisationForChannel(channelId, cdnName, toggleOn, reason) {
  const cdnNameLowercase = cdnName.toLowerCase();
  const action = toggleOn ? 'enable' : 'disable';
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/${action}-cdn-${cdnNameLowercase}-tokenisation`,
    JSON.stringify({ reason })
  );
}

export function getChannels(params) {
  return requestHandler.send('GET', `${PREFIX}/channels`, params);
}

export function getDevices() {
  return requestHandler.send('GET', `${PREFIX}/devices`);
}

export function getStreams(params) {
  return requestHandler.send('GET', `${PREFIX}/streams`, params);
}

/**
 * @param {integer} streamId
 * @param {{toggleOn: string; feature: string; reason: string}} info
 */
export function patchStream(streamId, info) {
  return requestHandler.send('PATCH', `${PREFIX}/streams/${streamId}`, JSON.stringify(info));
}

export function patchDevice(deviceId, path, reason) {
  return requestHandler.send('PATCH', `${PREFIX}/devices/${deviceId}/${path}`, JSON.stringify({ reason }));
}

export function getMarkets() {
  return requestHandler.send('GET', `${PREFIX}/markets`);
}

function patchMarketChannelProperties(marketChannelId, path, reason) {
  return requestHandler.send(
    'PATCH',
    `${PREFIX}/channels/markets/${marketChannelId}/${path}`,
    JSON.stringify({ reason })
  );
}

export function enableFullHdForMarketChannel(marketChannelId, reason) {
  return patchMarketChannelProperties(marketChannelId, 'enable-full-hd', reason);
}

export function disableFullHdForMarketChannel(marketChannelId, reason) {
  return patchMarketChannelProperties(marketChannelId, 'disable-full-hd', reason);
}

export function enableStartOverForMarketChannel(marketChannelId, reason) {
  return patchMarketChannelProperties(marketChannelId, 'enable-start-over', reason);
}

export function disableStartOverForMarketChannel(marketChannelId, reason) {
  return patchMarketChannelProperties(marketChannelId, 'disable-start-over', reason);
}

export function switchFhdToPrimaryForMarketChannel(marketChannelId, reason) {
  return patchMarketChannelProperties(marketChannelId, 'switch-full-hd-to-primary', reason);
}

export function switchFhdToBackupForMarketChannel(marketChannelId, reason) {
  return patchMarketChannelProperties(marketChannelId, 'switch-full-hd-to-backup', reason);
}

export function enableManifestFiltering(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/enable-manifest-filtering`,
    JSON.stringify({ reason })
  );
}

export function disableManifestFiltering(channelId, reason) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/channels/${channelId}/disable-manifest-filtering`,
    JSON.stringify({ reason })
  );
}

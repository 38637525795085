import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Input, Button, Panel } from 'react-bootstrap';
import { updateLiveEvent, saveLiveEvent } from 'src/scripts/actions/liveEvent';
import { getSeasons } from 'src/scripts/actions/season';
import { TV, NETWORK } from 'src/scripts/lib/libraries';
import ImageUpload from 'src/scripts/components/ImageUpload';
import SchedulingPanel from 'src/scripts/components/LiveEvent/internal/SchedulingPanel';
import EventTypePanel from 'src/scripts/components/LiveEvent/internal/EventTypePanel';
import MonetisationPanel from 'src/scripts/components/LiveEvent/internal/MonetisationPanel';
import AvailabilityPanel from 'src/scripts/components/LiveEvent/internal/AvailabilityPanel';
import TrackingPanel from 'src/scripts/components/LiveEvent/internal/TrackingPanel';
import LiveEventGroupPanel from 'src/scripts/components/LiveEvent/internal/LiveEventGroupPanel';
import { IMAGES } from 'src/scripts/lib/resourceGroups';
import { LIVE_EVENT_IMAGE_REF, LIVE_EVENT_LOGO_REF } from 'src/scripts/lib/imageRefs';
import { isRemoteEvent, isBroadcastEvent } from 'src/scripts/lib/liveEventTypes';
import { INSTANT_EVENT } from 'src/scripts/lib/liveEventScheduleTypes';
import { isFormValid } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/liveEventForm';
import { showFormValidation } from 'src/scripts/actions/form';
import { ANY_ASPECT_RATIO } from 'src/scripts/lib/keyArtFileUtility';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import TVSeriesSeasonSelect from 'src/scripts/components/TVSeriesSeasonSelect';
import UpNextSection from './UpNextSection';
import MultiImageUpload from '../../MultiImageUpload';
import { LIVE_EVENT_RATIO_LOGO } from 'src/scripts/lib/imageTypes.js';
import _ from 'lodash';
import ImageFactoryPreview from './ImageFactoryPreview';
import Dropdown from '../../Dropdown';
import DateTimePicker from 'src/scripts/components/DateTimePicker';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import classificationCodes from 'src/scripts/constants/classificationCodes';

export class LiveEventForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpNextEventPanelExpanded: false,
    };
    this.isInEditMode = this.isInEditMode.bind(this);
    this.saveLiveEvent = this.saveLiveEvent.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onSubtitleChange = this.onSubtitleChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onTvLiveEventClassificationCodeChange = this.onTvLiveEventClassificationCodeChange.bind(this);
    this.onTvLiveEventCurrentStartDateTimeChange = this.onTvLiveEventCurrentStartDateTimeChange.bind(this);
    this.onTvLiveEventCurrentEndDateTimeChange = this.onTvLiveEventCurrentEndDateTimeChange.bind(this);
    this.onTvLiveEventGenreChange = this.onTvLiveEventGenreChange.bind(this);
    this.onUpNextSectionFormClear = this.onUpNextSectionFormClear.bind(this);
    this.onUpNextEventPropertyChange = this.onUpNextEventPropertyChange.bind(this);
    this.updateCurrentEvent = this.updateCurrentEvent.bind(this);
    this.onTVSeriesSeasonChange = this.onTVSeriesSeasonChange.bind(this);
    this.onOztamPublisherIdChange = this.onOztamPublisherIdChange.bind(this);
    this.onLiveEventPositionChange = this.onLiveEventPositionChange.bind(this);

    props.updateLiveEvent({ library: props.library });
  }

  onNameChange = (event) => {
    this.props.updateLiveEvent({
      name: event.target.value,
    });
  };

  onSubtitleChange = (event) => {
    this.props.updateLiveEvent({
      subtitle: event.target.value,
    });
  };

  onDescriptionChange = (event) => {
    this.props.updateLiveEvent({
      description: event.target.value,
    });
  };

  onTvLiveEventGenreChange = (event) => {
    this.props.updateLiveEvent({
      genreId: +event.target.value,
    });
  };

  onTvLiveEventClassificationCodeChange = (event) => {
    this.props.updateLiveEvent({
      classificationCode: event.target.value,
    });
  };

  onTvLiveEventCurrentStartDateTimeChange = (dateTime) => {
    this.props.updateLiveEvent({
      programStartTime: dateTime || null,
    });
  };

  onTvLiveEventCurrentEndDateTimeChange = (dateTime) => {
    this.props.updateLiveEvent({
      programEndTime: dateTime || null,
    });
  };

  onUpNextEventPropertyChange(propertyName, value) {
    const updatedUpNextEvent = this.props.form.liveEvent.upNextEvent;
    updatedUpNextEvent[propertyName] = value;
    this.props.updateLiveEvent({
      upNextEvent: { ...updatedUpNextEvent },
    });
  }

  onUpNextSectionFormClear() {
    this.props.updateLiveEvent({
      upNextEvent: {
        name: '',
        subtitle: '',
        description: '',
        genreId: null,
        genreName: null,
        classificationCode: '',
        programStartTime: null,
        programEndTime: null,
      },
    });
  }

  updateCurrentEvent(newCurrentEvent) {
    this.props.updateLiveEvent({
      ...newCurrentEvent,
      upNextEvent: {
        name: '',
        subtitle: '',
        description: '',
        genreId: null,
        genreName: null,
        classificationCode: '',
        programStartTime: null,
        programEndTime: null,
      },
      imageId: undefined,
    });
  }

  onOztamPublisherIdChange = (oztamPublisherId) => {
    this.props.updateLiveEvent({
      oztamPublisherId,
    });
  };

  onTVSeriesSeasonChange = (tvSeriesId, seasonId) => {
    this.props.updateLiveEvent({
      tvSeriesId,
      seasonId,
    });
  };

  onLiveEventGroupChange = (liveEventGroupId) => {
    this.props.updateLiveEvent({ liveEventGroupId });
  };

  onLiveEventPositionChange = (event) => {
    this.props.updateLiveEvent({ position: event.target.value === 'unselected' ? null : event.target.value });
  };

  onProducerNotesInput = (event) => {
    this.props.updateLiveEvent({ producerNotes: event.target.value });
  };

  getImageResourceGroup() {
    switch (this.props.library) {
      case TV:
        return IMAGES.TV_LIVE_EVENTS;
      case NETWORK:
        return IMAGES.NETWORK_LIVE_EVENTS;
      default:
        throw new Error(`Library ${this.props.library} not supported`);
    }
  }

  isSubmitDisabled() {
    const imagesUploading = _.pickBy(this.props.multiImageUpload, (image) => image.uploading);
    const areImagesUploading = imagesUploading ? _.keys(imagesUploading).length > 0 : false;

    return (
      this.props.imageUpload.uploading ||
      this.props.logoUpload.uploading ||
      this.props.form.saving ||
      areImagesUploading
    );
  }

  isInEditMode() {
    return !!this.props.form.liveEvent.id;
  }

  getImagesWithTypes = (images, types) => {
    return images ? images.filter((image) => types.includes(image.type)) : [];
  };

  getGenreName = (genreId) => {
    return this.props.form.genres.find((genre) => genre.id === genreId).name;
  };
  saveLiveEvent = (event) => {
    event.preventDefault();

    const uploadedImages = [];
    for (const key of Object.keys(this.props.multiImageUpload)) {
      if (!this.props.multiImageUpload[key].isImageRemoved) {
        uploadedImages.push({
          id: this.props.multiImageUpload[key].imageId,
          type: this.props.multiImageUpload[key].type,
        });
      }
    }

    const formData = this.props.form.liveEvent;
    if (formData.upNextEvent && Number.isInteger(formData.upNextEvent.genreId)) {
      formData.upNextEvent.genreName = this.getGenreName(formData.upNextEvent.genreId);
    }
    const liveEvent = {
      id: formData.id,
      name: formData.name,
      subtitle: formData.subtitle,
      description: formData.description,
      upNextEvent: formData.upNextEvent,
      promoStartDate: formData.promoStartDate,
      availabilityStartDate: formData.availabilityStartDate,
      availabilityEndDate: formData.availabilityEndDate,
      /**
       * tvSeriesId is needed in the form validation to
       * determine if seasonId should be provided.
       */
      tvSeriesId: formData.tvSeriesId,
      seasonId: formData.seasonId,
      scheduleType: formData.scheduleType,
      eventType: formData.eventType,
      videoGroupId: formData.videoGroupId,
      preRoll: formData.preRoll,
      ssaiEnabled: formData.ssaiEnabled,
      hideStrap: formData.hideStrap,
      oztamPublisherId: formData.oztamPublisherId,
      isSports: formData.isSports,
      preview: formData.preview,
      images: uploadedImages,
      isFastChannel: formData.isFastChannel,
      multiUrlEnabled: formData.multiUrlEnabled,
      fastChannel: formData.fastChannel,
      producerNotes: formData.producerNotes,
      genreId: formData.genreId,
      genreName: Number.isInteger(formData.genreId) ? this.getGenreName(formData.genreId) : null,
      classificationCode: formData.classificationCode,
      programStartTime: formData.programStartTime,
      programEndTime: formData.programEndTime,
      contentTargetingCategories: formData.contentTargetingCategories,
      externalId: formData.externalId || null,
    };
    if (this.props.library === TV) {
      liveEvent.liveEventGroupId = formData.liveEventGroupId;
    }

    if (this.props.library === NETWORK) {
      liveEvent.position = formData.position;
    }

    if (isRemoteEvent(formData)) {
      liveEvent.remoteURL = formData.remoteURL;
      if (formData.ssaiEnabled && this.props.library === TV) {
        liveEvent.ssaiRemoteURL = formData.ssaiRemoteURL;
      }

      if (this.props.library === TV && formData.multiUrlEnabled) {
        liveEvent.liveEventUrls = formData.liveEventUrls;
      }
    }

    if (isBroadcastEvent(formData)) {
      liveEvent.catalogCodes = formData.catalogCodes;
      liveEvent.materialKeys = formData.materialKeys;
      liveEvent.ssaiEnabled = false;
      liveEvent.isSports = false;
      delete liveEvent.oztamPublisherId;
    }

    if (this.props.imageUpload.imageId) {
      liveEvent.imageId = this.props.imageUpload.imageId;
    }

    if (this.props.logoUpload.imageId) {
      liveEvent.logoId = this.props.logoUpload.imageId;
    }

    if (formData.scheduleType === INSTANT_EVENT) {
      delete liveEvent.promoStartDate;
      delete liveEvent.availabilityStartDate;
      delete liveEvent.availabilityEndDate;
    }

    if (
      !isFormValid(
        liveEvent,
        getValidationRules(this.props.form.initialLiveEventState, this.props.form.liveEvent)
      )
    ) {
      this.props.showFormValidation();
      return;
    }

    /**
     * tvSeriesId is not needed in the api call
     */
    delete liveEvent.tvSeriesId;

    this.props.saveLiveEvent(this.props.library, liveEvent);
  };

  renderAvailabilityPanel() {
    const { library } = this.props;
    const { liveEvent } = this.props.form;
    if (library === NETWORK) {
      return <AvailabilityPanel liveEvent={liveEvent} onChange={this.props.updateLiveEvent} />;
    }
    return null;
  }

  renderUpNextPanel() {
    return (
      <Panel header="Up Next" bsStyle="primary" collapsible>
        <UpNextSection
          isInEditMode={this.isInEditMode()}
          upNextEvent={this.props.form.liveEvent.upNextEvent}
          onUpNextEventPropertyChange={this.onUpNextEventPropertyChange}
          onUpNextSectionFormClear={this.onUpNextSectionFormClear}
          goLive={this.updateCurrentEvent}
          genres={this.props.form.genres}
          validationErrors={this.props.form.validationErrors}
        />
      </Panel>
    );
  }

  renderLibraryPanel() {
    /**
     * Replace `undefined' with 0 for seasonId so that the select form is
     * correctly reset when tvSeriesId is unselected.
     *
     * Keep it consistent for tvSeriesId.
     */
    return (
      <Panel header="TV" bsStyle="primary">
        <TVSeriesSeasonSelect
          ref="liveEventLibraryPanel"
          onChange={this.onTVSeriesSeasonChange}
          getSeasons={this.props.getSeasons}
          seasons={this.props.form.seasons}
          initialTvSeries={this.props.form.liveEvent.partOfSeries}
          seasonId={this.props.form.liveEvent.seasonId || 0}
          tvSeriesId={this.props.form.liveEvent.tvSeriesId || 0}
          validationErrors={this.props.form.validationErrors}
        />
      </Panel>
    );
  }

  renderTrackingPanel() {
    if (this.props.library === TV) {
      return (
        <TrackingPanel
          liveEvent={this.props.form.liveEvent}
          validationErrors={this.props.form.validationErrors}
          onOztamPublisherIdChange={this.onOztamPublisherIdChange}
        />
      );
    }
    return null;
  }

  renderLiveEventGroupPanel() {
    if (this.props.library === TV) {
      return (
        <LiveEventGroupPanel
          liveEvent={this.props.form.liveEvent}
          onLiveEventGroupChange={this.onLiveEventGroupChange}
          validationErrors={this.props.form.validationErrors.liveEventGroupId}
        />
      );
    }
    return null;
  }

  renderPositionOptions = () => {
    const options = [
      <option key={0} value="unselected">
        Select a position
      </option>,
    ];
    [...Array(5).keys()].forEach((i) =>
      options.push(
        <option key={i + 1} value={i + 1}>
          {i + 1}
        </option>
      )
    );
    return options;
  };

  renderPositionInput() {
    if (this.props.library === NETWORK) {
      return (
        <Input
          type="select"
          ref="liveEventPosition"
          label="Position"
          defaultValue="default"
          value={this.props.form.liveEvent && this.props.form.liveEvent.position}
          onChange={this.onLiveEventPositionChange}
          bsStyle={this.props.form.validationErrors.position ? 'error' : null}
        >
          {this.renderPositionOptions()}
        </Input>
      );
    }
    return null;
  }

  renderTvLiveEventAdditionalMetadata() {
    return (
      <div>
        <Input
          type="select"
          label="Genre"
          onChange={this.onTvLiveEventGenreChange}
          value={this.props.form.liveEvent.genreId}
        >
          <option value={null}>Select a genre</option>
          {(this.props.form.genres || []).map((genre) => {
            return (
              <option key={genre.id} value={genre.id}>
                {genre.name}
              </option>
            );
          })}
        </Input>
        <Dropdown
          items={classificationCodes}
          // eslint-disable-next-line react/jsx-boolean-value
          isOptionValueNull={true}
          label="Classification Rating"
          onChange={this.onTvLiveEventClassificationCodeChange}
          currentValue={this.props.form.liveEvent.classificationCode}
        />
        <div className={`form-group ${this.props.form.validationErrors.programStartTime && 'error'}`}>
          <label>Start Date & Time</label>
          <DateTimePicker
            ref="currentProgramStartDateTime"
            onChange={this.onTvLiveEventCurrentStartDateTimeChange}
            value={this.props.form.liveEvent.programStartTime}
          />
        </div>
        <div className={`form-group ${this.props.form.validationErrors.programEndTime && 'error'}`}>
          <label>End Date & Time</label>
          <DateTimePicker
            ref="currenEventEndDateTime"
            onChange={this.onTvLiveEventCurrentEndDateTimeChange}
            value={this.props.form.liveEvent.programEndTime}
          />
        </div>
      </div>
    );
  }

  renderImagePreviews() {
    return this.props.imageUpload.s3Path ? (
      <ImageFactoryPreview s3Path={this.props.imageUpload.s3Path} />
    ) : null;
  }

  render() {
    const logoRestrictions = {
      minHeight: 0,
      minWidth: 0,
      ratioThreshold: ANY_ASPECT_RATIO,
    };

    return (
      <div>
        {this.props.isFetchError && (
          <ConfirmationDialog
            ref="confirmationModal"
            errorMessage={'Something went wrong. Please try again later'}
            close={this.props.close}
            cancelButtonLabel={'OK'}
            buttonClass="danger"
            showConfirmationButton={false}
          />
        )}
        {!this.props.isFetchError && (
          <form
            className="form"
            onSubmit={this.saveLiveEvent}
            ref="liveEventForm"
            data-pw="tv-live-event-form"
          >
            <Panel header="Current Event" bsStyle="primary" eventKey="1">
              {/* Do Not Remove id or data-pw attributes */}
              <Input
                labelClassName="required"
                type="text"
                label="Live Event Name"
                id="current-event-name"
                placeholder="Enter name"
                ref="liveEventName"
                maxLength="100"
                onChange={this.onNameChange}
                value={this.props.form.liveEvent.name || ''}
                bsStyle={this.props.form.validationErrors.name ? 'error' : null}
                data-pw="current-event-name"
              />
              <Input
                type="text"
                label="Live Event Subtitle"
                id="current-event-subtitle"
                placeholder="Add a subtitle of the live event"
                ref="liveEventSubtitle"
                maxLength="100"
                onChange={this.onSubtitleChange}
                value={this.props.form.liveEvent.subtitle}
                bsStyle={this.props.form.validationErrors.subtitle ? 'error' : null}
                data-pw="current-event-subtitle"
              />
              <Input
                type="textarea"
                label="Description"
                id="current-event-description"
                ref="liveEventDescription"
                maxLength="2000"
                placeholder="Add a description of the live event"
                onChange={this.onDescriptionChange}
                value={this.props.form.liveEvent.description}
                bsStyle={this.props.form.validationErrors.description ? 'error' : null}
                data-pw="current-event-description"
              />
              {this.props.library === TV && this.renderTvLiveEventAdditionalMetadata()}
              {this.renderPositionInput()}
            </Panel>
            <EventTypePanel
              ref="liveEventEventTypePanel"
              library={this.props.library}
              liveEvent={this.props.form.liveEvent}
              onChange={this.props.updateLiveEvent}
              formValidationErrors={this.props.form.validationErrors}
              isFastChannel={this.props.form.liveEvent.isFastChannel}
              fastChannel={this.props.form.liveEvent.isFastChannel && this.props.form.liveEvent.fastChannel}
            />
            {this.props.library === TV && this.renderUpNextPanel()}
            {this.renderLiveEventGroupPanel()}
            {isRemoteEvent(this.props.form.liveEvent) ? this.renderTrackingPanel() : null}
            {isRemoteEvent(this.props.form.liveEvent) ? (
              <MonetisationPanel
                ref="liveEventMonetisationPanel"
                library={this.props.library}
                liveEvent={this.props.form.liveEvent}
                onChange={this.props.updateLiveEvent}
                videoGroups={this.props.videoGroups}
              />
            ) : null}
            {this.renderAvailabilityPanel()}
            <SchedulingPanel
              ref="liveEventSchedulingPanel"
              liveEvent={this.props.form.liveEvent}
              onChange={this.props.updateLiveEvent}
              formValidationErrors={this.props.form.validationErrors}
            />
            {this.props.library === TV && this.renderLibraryPanel()}
            <Panel bsStyle="primary" header="Artwork">
              <ImageUpload
                required
                resourceGroup={this.getImageResourceGroup()}
                label="Image"
                imageUploadRef={LIVE_EVENT_IMAGE_REF}
              />
              {this.renderImagePreviews()}
              <hr />
              <ImageUpload
                required
                restrictions={logoRestrictions}
                resourceGroup={this.getImageResourceGroup()}
                label="Logo"
                imageUploadRef={LIVE_EVENT_LOGO_REF}
              />
              <MultiImageUpload
                resourceGroup={IMAGES.TV}
                imageTypes={[LIVE_EVENT_RATIO_LOGO]}
                limit={1}
                existingImages={this.getImagesWithTypes(this.props.form.liveEvent.images, [
                  LIVE_EVENT_RATIO_LOGO,
                ])}
              />
            </Panel>
            <Panel header="Notes" bsStyle="primary">
              <Input
                id="producer-notes-input"
                type="textarea"
                label="Producer Notes"
                ref="producerNotes"
                onInput={(e) => this.onProducerNotesInput(e)}
                value={this.props.form.liveEvent.producerNotes}
              />
            </Panel>
            {this.props.form.errorMessage && (
              <FormErrorMessage
                className="live-event-server-error-message"
                message={this.props.form.errorMessage}
              />
            )}
            <div className="modal-footer">
              <Button
                id="video-modal-close"
                type="button"
                ref="closeButton"
                className="form__button"
                onClick={this.props.close}
              >
                Close
              </Button>
              <Button
                ref="submitButton"
                id="video-modal-submit"
                type="submit"
                className="form__button"
                bsStyle="primary"
                disabled={this.isSubmitDisabled()}
                data-pw="video-modal-submit"
              >
                Save
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

LiveEventForm.propTypes = {
  imageUpload: PropTypes.object,
  logoUpload: PropTypes.object,
  close: PropTypes.func,
  form: PropTypes.object,
  updateLiveEvent: PropTypes.func,
  saveLiveEvent: PropTypes.func,
  videoGroups: PropTypes.array,
  genres: PropTypes.array,
  getSeasons: PropTypes.func,
  library: PropTypes.string.isRequired,
  isFetchError: PropTypes.bool.isRequired,
  showFormValidation: PropTypes.func,
  multiImageUpload: PropTypes.object,
  liveEventUrls: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isFetchError: state.liveEventForm.isFetchError,
    imageUpload: state.imageUploads[LIVE_EVENT_IMAGE_REF],
    logoUpload: state.imageUploads[LIVE_EVENT_LOGO_REF],
    form: state.liveEventForm,
    videoGroups: state.liveEventForm.videoGroups,
    multiImageUpload: state.multiImageUpload,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLiveEvent: (liveEvent) => dispatch(updateLiveEvent(liveEvent)),
    saveLiveEvent: (library, liveEvent) => dispatch(saveLiveEvent(library, liveEvent)),
    getSeasons: (seasonParams) => dispatch(getSeasons(seasonParams)),
    showFormValidation: () => dispatch(showFormValidation()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveEventForm);
